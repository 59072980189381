import { ContactUsInfo } from './contact-us.types';

export const CONTACT_US: ContactUsInfo = {
  cnh: [
    {
      title: 'US Dealers',
      flag_source: 'assets/images/us.svg',
      registration: {
        message: 'PPP Quote and Registration Support',
        phone: '800-296-1043',
      },
      claim: {
        message: 'PPP Claims Support',
        phone: '800-296-1220',
      },
    },
    {
      title: 'Canadian Dealers',
      flag_source: 'assets/images/ca.svg',
      registration: {
        message: 'PPP Quote and Registration Support',
        phone: '800-296-1064',
      },
      claim: {
        message: 'PPP Claims Support',
        phone: '800-296-1220',
      },
    },
  ],
  epg: [
    {
      title: 'United States',
      flag_source: 'assets/images/us.svg',
      subtitle: 'Phone: 866-408-2881',
      registration: {
        message: 'Quote and Registrations',
        email: 'EPG_sales@assurant.com',
      },
      claim: {
        message: 'Claims',
        email: 'EPG_esppclaims@assurant.com',
      },
    },
  ],
  ppsp: [
    {
      title: 'Canada',
      flag_source: 'assets/images/ca.svg',
      subtitle: 'Phone: 888-952-5511 or 604-952-5511',
      registration: {
        message: 'Quote and Registrations',
        email: 'warranty@protectionpoint.ca',
      },
      claim: {
        message: 'Claims',
        email: 'warranty@protectionpoint.ca',
      },
    },
  ],
};
