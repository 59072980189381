import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
type PrefixDictionary = { [host: string]: string };

@Injectable({
    providedIn: 'root'
})
export class SiteTitleService {

    //DI Title
    constructor(private siteTitle: Title) { }

    private readonly defaultPrefix: string = 'Assurant Commercial Equipment Protection';

    private readonly prefixMap: PrefixDictionary = {
        'localhost': 'Assurant Commercial Equipment Protection',
        'dev-ppp.epgins.com': 'PPP',
        'espp-integration-model.epgins.com': 'PPP',
        'ppp.cnhinsurance.com': 'PPP',
        
        'model-espp.assurant.com': 'Assurant Commercial Equipment Protection',
        'model-espp-cnh.assurant.com': 'PPP',
        'model-espp-protectionpoint.assurant.com': 'Protection Point',
        'model-espp-bomag.assurant.com': 'Bomag ESPP',
        
        'qa-espp.assurant.com': 'Assurant Commercial Equipment Protection',
        'qa-espp-cnh.assurant.com': 'PPP',
        'qa-espp-protectionp': 'Protection Point',
    }

    private gettitlePrefix(): string {
        const host = window.location.host;
        if (this.prefixMap[host]) {
            return this.prefixMap[host];
        }
        return this.defaultPrefix;
    }

    public setTitle(pageTitle: string | null): void {
        console.log(this.gettitlePrefix())
        this.siteTitle.setTitle(this.gettitlePrefix() + (pageTitle ? ' | ' + pageTitle : ''));
    }
}
