import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivationEnd, Data, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { combineAll, debounceTime, distinctUntilChanged, exhaustMap, filter, map, pairwise, startWith, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { ErrorReporterComponent } from 'src/app/core/components/error-reporter/error-reporter.component';
import { ClaimPermission } from 'src/app/core/permissions/ClaimPermission';
import { QuotePermission } from 'src/app/core/permissions/QuotePermission';
import { EsppQuoterComponent } from 'src/app/modules/espp/modules/espp-quoter/espp-quoter.component';
import { QuoteFormService } from '../../services/quote-form/quote-form.service';
import { QuoteService } from '../../services/quote.service';
import { SearchSuggestinsService, Suggestion } from '../../services/search-suggestions/search-suggestins.service';
import { Location } from '@angular/common';
import { Application } from '../../services/app-information/application.enum';
import { NewClaimFormComponent } from 'src/app/modules/espp/claims/components/new-claim-form/new-claim-form.component';
import { ClaimService } from '../../services/claim/claim.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { EnrolledUnitPermission } from 'src/app/core/permissions/EnrolledUnitPermission';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { Permission } from 'src/app/core/auth/permissions.types';
import { MessageDialogComponent } from 'src/app/modules/espp/claims/components/message-dialog/message-dialog.component';
import { AppInformationService } from '../../services/app-information/app-information.service';


let browserRefresh = false;

@Component({
    selector: 'app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent implements OnInit, AfterViewInit, OnDestroy {

    /**Check if the router url contains the specified route
    @param {string} route
    @returns
    @memberof AppNavigationComponent
    */
    hasRoute(route: string) {
        return this.router.url.includes(route);
    }

    public isExpanded: boolean = true;
    public featureFlags!: any[]
    @ViewChild(MatSidenav, { static: false })
    sidenav!: MatSidenav;


    closeSideNav() {
        if (!this.sidenav) return;
        if (this.sidenav.mode == 'over') {
            this.sidenav.close();
        }
    }

    @ViewChild('search') searchInput!: ElementRef;
    public activatedRouteSub: Subscription = new Subscription();
    // canSeeQuotes: boolean = false;
    // canSeeRegisteredUnites: boolean = false;
    // canSeeClaims: boolean = false;
    canQuote: boolean = false;
    showQuoteButton: boolean = false;
    usersName: string = '';
    isSearching: boolean = false;
    // buttonTitle: string = 'Get Quote';
    showSearch = true;
    showUser = true;
    showAppsNavigation = true

    showLoading = false
    isNavigating = false

    constructor(
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        private router: Router,
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private claimService: ClaimService,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private quoteFormService: QuoteFormService,
        private suggestionService: SearchSuggestinsService,
        private quoteService: QuoteService,
        private dialog: MatDialog,
        private observer: BreakpointObserver,
        private bottomSheet: MatBottomSheet,
        private changeDetector: ChangeDetectorRef,
        private appInfoService: AppInformationService) {


    }

    get isQuoteDetails() {
        const url = this.router.url
        const segments = url.split('/')
        //return (this.router.url).split('/').includes('espp') && (this.router.url).split('/').length == 5 && segments.includes('quotes')
        return segments.includes('espp') && segments.includes('quotes') && segments.includes('apps') && segments.length === 5
    }

    get isApplication() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('application')
    }

    get isQuotesQueue() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('quotes') && segments.includes('apps') && segments.length === 4
    }

    public get isEnrolledUnits() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('enrolled_units') && segments.includes('apps') && segments.length === 4
    }

    public get isEnrolledUnitDetail() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('enrolled_units') && segments.includes('apps') && segments.length === 5
    }

    private getApplicationKey() {
        if (this.isQuotesQueue) {
            return Application.ESPP_QUOTE_QUEUE
        } else if (this.isQuoteDetails || this.isEnrolledUnitDetail) {
            return Application.ESPP_QUOTE_DETAILS
        } else if (this.isEnrolledUnits) {
            return Application.ESPP_ENROLLED_UNITS
        } else if (this.isEnrolledUnitDetail) {
            return Application.ESPP_ENROLLED_UNIT_DETAIL
        } else {
            return Application.ESPP_APPLICATION
        }
    }

    private setUpNavigation() {
        const application = this.getApplicationKey();
        switch (application) {
            case Application.ESPP_QUOTE_QUEUE:
                this.showUser = true
                this.showSearch = true
                this.showAppsNavigation = true
                this.showQuoteButton = true;
                break;
            case Application.ESPP_QUOTE_DETAILS:
            case Application.ESPP_APPLICATION:
            case Application.ESPP_ENROLLED_UNIT_DETAIL:
                // this.buttonTitle = 'Back';
                this.showUser = true
                this.showSearch = false
                this.showAppsNavigation = false
                this.showQuoteButton = true;
                break;
            case Application.ESPP_ENROLLED_UNITS:
                this.canQuote = false;
                this.showUser = true
                this.showSearch = true
                this.showAppsNavigation = true
                this.showQuoteButton = false;
                break;

        }
    }

    public get canSeeQuotes() {
        return this.authorizationService.hasPermission([Permission.READ_QUOTE, Permission.VIEW_QUOTES, Permission.CREATE_QUOTE])
    }

    public get canSeeRegisteredUnites() {
        return this.authorizationService.hasPermission([Permission.VIEW_ENROLLED_UNITS, Permission.VIEW_ENROLLED_UNIT])
    }

    public get canSeeClaims() {
        return this.authorizationService.hasPermission([Permission.READ_CLAIM, Permission.CREATE_CLAIM, Permission.VIEW_CLAIMS])
    }
    
    /**
     * Determines if the training section should be visible.
     * Only CHN users cannot see the training part.
     * @returns {boolean} True if the user can see the training section, false otherwise.
     * @memberof AppNavigationComponent
     */
    public get canSeeTraining() {
        return !this.authService.isCNH
    }

    ngOnInit(): void {
        this.setUpNavigation();
        this.appInfoService.featureFlags.subscribe((newFlags) => this.featureFlags = newFlags)
        const user = this.authService.user
            if (user) {
                this.canQuote = user!.permissions.map(permission => permission.name).includes(QuotePermission.CREATE_QUOTE)
                // this.canSeeQuotes = user!.permissions.map(permission => permission.name).includes(QuotePermission.READ_QUOTE)
                // this.canSeeRegisteredUnites = (user!.permissions.map(permission => permission.name).includes(QuotePermission.READ_QUOTE) || user!.permissions.map(p => p.name).includes(EnrolledUnitPermission.READ_POLICY))
                // this.canSeeClaims = user!.permissions.map(permission => permission.name).includes(ClaimPermission.CREATE_CLAIM)
                this.usersName = user.first_name + ' ' + user.last_name
            }


        //router event
        this.router.events
            .pipe(
                filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.data).length > 0)),
                map(e => e instanceof ActivationEnd ? e.snapshot.data : {}),
            )
            .subscribe(params => {
                // const data = params['ESPP_NAV'];
                // if (data) {
                //     this.buttonTitle = data.buttonText;
                //     this.showUser = data.showUser
                //     this.showSearch = data.showSearch
                //     this.showAppsNavigation = data.showNavigation
                // }
                this.setUpNavigation()
                //this.sidenav.close()
            },
                error => { });


        this.router.events.subscribe((event) => {

            this.loadingServiceFn(event);

        });
    }

    public loadingServiceFn(event){

        if (event instanceof NavigationStart) {
            this.showLoading = true;
            this.isNavigating = true;
        }
        if (event instanceof NavigationError) {
            this.showLoading = false;
            this.isNavigating = false
        }
        if (event instanceof NavigationEnd) {

            // Hide loading after 1 sec            
            setTimeout(() => {
                this.showLoading = false;
            }, 1000);
            this.isNavigating = false

        }

    }

    public suggestions: BehaviorSubject<Suggestion[]> = new BehaviorSubject<Suggestion[]>([]);

    ngAfterViewInit() {
        this.observer.observe(['(max-width: 1450px)'])
            .pipe(
                tap(res => {
                    console.log(res)
                })
            )
            .subscribe((res) => {
                //if(!this.sidenav) return;
                if (res.matches) {
                    this.sidenav.mode = 'over';
                    this.sidenav.close();
                } else {
                    this.sidenav.mode = 'side';
                    this.sidenav.open();
                }
                //return;
            });

        if (this.searchInput) {
            fromEvent(this.searchInput.nativeElement, 'keyup')
                .pipe(
                    map((event: any) => event.target.value),
                    // if character length greater then 4
                    filter(res => res && res.length > 3)
                    // Time in milliseconds between key events
                    , debounceTime(1000)
                    // If previous query is diffent from current   
                    , distinctUntilChanged(),
                    // switch map
                    exhaustMap(term => this.search(term))
                )
                .subscribe((results) => {
                    //console.log(results)
                });


            fromEvent(this.searchInput.nativeElement, 'keyup')
                .pipe(
                    map((event: any) => event.target.value),
                    // if character length greater then 1
                    filter(res => res && res.length > 3)
                    // Time in milliseconds between key events
                    , debounceTime(1000)
                    // If previous query is diffent from current   
                    , distinctUntilChanged(),
                    // switch map
                    exhaustMap(term => this.suggestionService.getSuggestions(term))
                )
                .subscribe((results) => {
                    this.suggestions.next(results)
                });

            merge(
                fromEvent(this.searchInput.nativeElement, 'focus'),
                fromEvent(this.searchInput.nativeElement, 'focusout')
            )
                .pipe(map((event: any) => event.type == 'focus' ? true : false))
                .subscribe(isFocused => {
                    const value = this.searchInput.nativeElement.value;
                    if (!value) {
                        this.isSearching = isFocused;
                    }
                })
        }
        this.changeDetector.detectChanges();

    }

    public resetQueue() {
        this.isSearching = !this.isSearching;
        this.searchInput.nativeElement.value = ''
        this.quoteService.getQuotes().subscribe((response) => {
            this.quoteService.addQuotes(response.data)
        });
    }

    private search(term: string): Observable<object> {
        if (this.quoteApp) {
            return this.quoteService.search(term)
        } else if (this.enrolledUnitsApp) {
            return of([]);
        } else {
            return of([]);
        }
    }

    get showEsppNav() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp')
    }

    get quoteApp() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('quotes')
    }

    get enrolledUnitsApp() {
        const url = this.router.url
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('quotes')
    }

    get claimsApp() {
        let urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams = {};
        urlTree.fragment = null; // optional
        const url = urlTree.toString()
        const segments = url.split('/')
        return segments.includes('espp') && segments.includes('claims')
    }

    public get canClaimFeatureFlag(): boolean {
        // If the feature flag is present then show updated message
            if(this.featureFlags.length > 0) {
                return this.featureFlags.findIndex(flags => flags.name === 'CLAIMS_DECOM') > -1 ? false : true;
            }
            return true
    }

    ngOnDestroy(): void {
        this.activatedRouteSub.unsubscribe();
    }

    logout(): void {
        this.authService.logout();
    }

    async logoutOkta() {
        // await this.oktaAuth.signOut();
        // this.logout();
    }

    showQuoteForm(event) {
        if (this.claimsApp) {
            //emitevent
            this.claimService.currentClaim.next(null);
            this.router.navigate(['apps', 'espp', 'claims'])
            this.dialog.open(NewClaimFormComponent,{
                panelClass:'claim-dialog',
            })
            return;
        }
        this.quoteFormService.showQuoteForm()
    }

    public openContactUs() {
        this.bottomSheet.open(ContactUsComponent)
    }

    public get userInformation() {
        const tokens = this.oktaAuth.authStateManager.getAuthState();
        var user: any = tokens?.idToken?.claims.email;
        return user;
    }

    public showMessageDialog() {

        if (this.claimsApp) {
            return;
        }

        let theUser = this.authService.user;

        let currentMfrProgram = 'EPG';
        if (theUser != null) {
            const mfrProgram = theUser.mfr_programs.find(program => program.current == true);
            currentMfrProgram = mfrProgram?.name ?? 'EPG';
        }

        if (currentMfrProgram != 'CNH') {
            return;
        }else{
            this.showLoading = false

        }

        const dialogRef = this.dialog.open(MessageDialogComponent, {
            disableClose: true,
            width: '600px',
            height: '300px',
        });

        dialogRef.afterClosed().subscribe(() => {
            if (this.isNavigating) {
                this.showLoading = true;
            }
        });

    }

}