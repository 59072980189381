<!-- <div class="btn-container" *ngIf="hasRoute('quotes')">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="30px" height="30px" class=""><g><path style="fill:#2196F3;" d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192  c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667  C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328  c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192  C509.872,262.42,510.655,261.246,511.189,259.954z" data-original="#2196F3"/><path d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88  L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192  c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/><path d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667  c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/></g></svg>
    <button *ngIf="canQuote || showQuoteButton" (click)="showQuoteForm($event)" class="start-new">
        Start Quote
    </button>
</div>

<div class="btn-container" *ngIf="hasRoute('claims')">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="30px" height="30px" class=""><g><path style="fill:#2196F3;" d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192  c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667  C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328  c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192  C509.872,262.42,510.655,261.246,511.189,259.954z" data-original="#2196F3"/><path d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88  L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192  c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/><path d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667  c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/></g></svg>
    <button *ngIf="canQuote || showQuoteButton" (click)="showQuoteForm($event)" class="start-new">
        Start Claim
    </button>
</div> -->

<!-- <div class="btn-container" *ngIf="staffMember && hasRoute('documents')">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="30px" height="30px" class=""><g><path style="fill:#2196F3;" d="M511.189,259.954c1.649-3.989,0.731-8.579-2.325-11.627l-192-192  c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l173.803,173.803H10.667  C4.776,245.213,0,249.989,0,255.88c0,5.891,4.776,10.667,10.667,10.667h464.917L301.803,440.328  c-4.237,4.093-4.355,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l192-192  C509.872,262.42,510.655,261.246,511.189,259.954z" data-original="#2196F3"/><path d="M309.333,458.546c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571L486.251,255.88  L301.781,71.432c-4.093-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l192,192  c4.164,4.165,4.164,10.917,0,15.083l-192,192C314.865,457.426,312.157,458.546,309.333,458.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/><path d="M501.333,266.546H10.667C4.776,266.546,0,261.771,0,255.88c0-5.891,4.776-10.667,10.667-10.667h490.667  c5.891,0,10.667,4.776,10.667,10.667C512,261.771,507.224,266.546,501.333,266.546z" data-original="#000000" class="active-path" data-old_color="#000000" style="fill:#FFFFFF"/></g></svg>
    <button (click)="uploadDocument()" class="start-new">
        Add Document
    </button>
</div> -->


<div class="loadingSpinner" *ngIf="showLoading" >
    <div id="espp-login" >
        <mat-spinner  class="mx-auto pt-3"></mat-spinner>
    </div>
</div>

<mat-toolbar class="top-nav">
    <div class="brand mx-auto">
        <!-- <img src="assets/images/epg-logo.svg"> -->
    </div>
    <div class="hamburger">
        <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>


<mat-sidenav-container autosize *ngIf="showEsppNav">
    <mat-sidenav #sidenav="matSidenav" mode="side" opened>
        <div class="d-flex flex-column flex-shrink-0 h-100">
            <a [routerLink]="['espp']">
                <div class="brand mx-auto">
                    <!-- <img src="assets/images/epg-logo.svg"> -->
                </div>
            </a>
            <div>
                <mat-nav-list>
                    <mat-list-item (click)="isExpanded = !isExpanded" class="expand-nav">
                        <mat-icon mat-list-icon class="material-icons-round mx-auto white-icon chevron-button sidenav-button" [ngClass]="{'rotate' : isExpanded}">arrow_forward</mat-icon>
                    </mat-list-item>
                    <!-- <mat-list-item (click)="isExpanded = !isExpanded" class="expand-nav">
                        <i mat-list-icon class="material-icons-round mx-auto white-icon chevron-button sidenav-button" [ngClass]="{'rotate' : isExpanded}">arrow_forward</i>
                    </mat-list-item> -->
                    <!-- <mat-list-item *ngIf="canQuote || showQuoteButton" (click)="showQuoteForm($event)">
                        <mat-icon mat-list-icon class="material-icons-round">add_circle</mat-icon>
                        <span *ngIf="isExpanded && hasRoute('quotes')" class="ps-1 pe-3">Start Quote</span>
                        <span *ngIf="isExpanded && hasRoute('claims')" class="ps-1 pe-3">Start Claim</span>
                        <span *ngIf="isExpanded && hasRoute('documents')" class="ps-1 pe-3">Add Document</span>
                    </mat-list-item> -->
                    <span *ngIf="hasRoute('quotes')">
                        <mat-list-item hideIfUnauthorized="CREATE_QUOTE" (click)="showQuoteForm($event)" class="start-button">
                            <mat-icon mat-list-icon class="material-icons-round start-icon">add_circle_outline</mat-icon>
                            <span *ngIf="isExpanded" class="ps-1 pe-3">Start Quote</span>
                        </mat-list-item>
                    </span>
                    <span *ngIf="hasRoute('claims')">
                        <mat-list-item *ngIf="canClaimFeatureFlag" hideIfUnauthorized="CREATE_CLAIM" (click)="showQuoteForm($event)" class="start-button">
                            <mat-icon mat-list-icon class="material-icons-round start-icon">add_circle_outline</mat-icon>
                            <span *ngIf="isExpanded" class="ps-1 pe-3">Start Claim</span>
                        </mat-list-item>
                    </span>

                    <mat-list-item routerLink="espp/quotes" routerLinkActive="active" *ngIf="canSeeQuotes" (click)="closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">list_alt</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Quotes</span>
                    </mat-list-item>
                    <mat-list-item routerLink="espp/submitted_applications" routerLinkActive="active" *ngIf="canSeeQuotes" (click)="closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round"><span class="material-icons-round">
                            <span class="material-icons-round">post_add</span>
                            </span></mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Applications</span>
                    </mat-list-item>
                    <mat-list-item routerLink="espp/enrolled_units" routerLinkActive="active" *ngIf="canSeeRegisteredUnites" (click)="closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">checklist_rtl</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Processed</span>
                    </mat-list-item>
                    <mat-list-item routerLink="espp/claims" routerLinkActive="active" *ngIf="canSeeClaims" (click)="showMessageDialog();closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">edit_note</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Claims</span>
                    </mat-list-item>
                    <mat-list-item routerLink="espp/documents" routerLinkActive="active" (click)="closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">save_alt</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Documents</span>
                    </mat-list-item>
                    <mat-list-item routerLink="espp/training" routerLinkActive="active" *ngIf="canSeeTraining" (click)="closeSideNav()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">videocam</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Training</span>
                    </mat-list-item>
                    <!-- <mat-list-item>
                        {{userInformation}}
                    </mat-list-item> -->
                </mat-nav-list>
            </div>
            
            <div class="mt-auto">
                <mat-nav-list>
                    <mat-list-item class="nav-links" (click)="openContactUs()">
                        <mat-icon mat-list-icon class="material-icons-round">perm_phone_msg</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Contact Us</span>
                    </mat-list-item>
                    <mat-list-item (click)="logout()" class="nav-links">
                        <mat-icon mat-list-icon class="material-icons-round">logout</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Sign Out</span>
                    </mat-list-item>
                    <!-- <mat-list-item>
                        <mat-icon mat-list-icon class="material-icons-round">settings</mat-icon>
                        <span *ngIf="isExpanded" class="ps-1 pe-3">Settings</span>
                    </mat-list-item> -->
                </mat-nav-list>
            </div>
        </div>
    </mat-sidenav>
    <div>
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>