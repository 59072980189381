// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const apiPath = (): string => {
    console.log(window.location.hostname)
    switch (window.location.hostname) {
        case 'espp-integration-model.epgins.com':
        case 'www.espp-integration-model.epgins.com':
            return window.location.protocol + '//espp-api-integration-model.epgins.com'
        case 'espp-qa.epgins.com':
        case 'espp-integration-qa.epgins.com':
        case 'qa-espp-protectionpoint.assurant.com':
        case 'qa-espp-cnh.assurant.com':
        case 'qa-espp-bomag.assurant.com':
            return window.location.protocol + '//espp-api-qa.epgins.com'
        default:
            return window.location.protocol + '//espp-api-model.epgins.com';
    }
}

export const environment = {
    production: false,
    esppApiBase: apiPath()
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.